export type IconThemeProps = {
  size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  variant: "normal";
};

// 1x => lg
// 2x => 2xl

export const iconTheme = {
  variant: {
    normal: "fill-current inline",
  },
  size: {
    xs: "icon-xs w-3 h-3",
    sm: "icon-sm w-3 h-3",
    md: "icon-md w-4 h-4",
    lg: "icon-lg w-5 h-5",
    xl: "icon-1x w-6 h-6",
    "2xl": "icon-2x w-8 h-8",
  },
};
