import { ComponentTheme } from "hardcover-ui/types/base";

export type ContainerThemeProps = {
  variant: "layout" | "primary" | "form";
  size: "xs" | "sm" | "md" | "lg" | "xl";
};

export const containerTheme: ComponentTheme<ContainerThemeProps> = {
  variant: {
    layout: "mx-auto px-2 lg:px-0 my-4",
    primary: "mx-auto",
    form: "border-t md:border dark:border-gray-600 border-gray-300 md:rounded-lg py-2 px-2",
  },
  size: {
    xs: "max-w-lg",
    sm: "max-w-3xl",
    md: "max-w-5xl",
    lg: "max-w-7xl",
    xl: "w-full",
  },
};
