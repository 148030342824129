import { ComponentTheme } from "hardcover-ui/types/base";

export type HeadingThemeProps = {
  variant: "primary" | "section" | "none" | "title";
  size: "none" | "xs" | "sm" | "md" | "lg" | "lxg" | "xl" | "xxl";
  level: 1 | 2 | 3 | 4 | 5 | 6;
};

const headingBaseStyles = "font-serif text-gray-800 dark:text-gray-200";

export const headingTheme: ComponentTheme<HeadingThemeProps> = {
  variant: {
    primary: `${headingBaseStyles}`,
    section: "font-semibold tracking-wider uppercase",
    none: "",
    title: "font-serif",
  },
  size: {
    none: "",
    xs: "text-lg",
    sm: "text-xl",
    md: "text-2xl",
    lg: "text-3xl",
    lxg: "text-4xl",
    xl: "text-5xl",
    xxl: "text-7xl",
  },
};
