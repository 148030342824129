"use client";

import Container from "hardcover-ui/components/Container";
import Heading from "hardcover-ui/components/Heading";
import Icon from "hardcover-ui/components/Icon";
import Text from "hardcover-ui/components/Text";

import faMagnifyingGlass from "icons/solid/magnifying-glass.svg";
import faBookmark from "icons/solid/bookmark.svg";
import faCommentSmile from "icons/solid/comment-smile.svg";
import faTelescope from "icons/solid/telescope.svg";

export default function SubHeaderFeatures() {
  return (
    <Container
      size="lg"
      className="grid grid-cols-2 sm:grid-cols-4 gap-2 mt-4 overflow-hidden"
    >
      <div className="dark:bg-gray-900 p-6 rounded-lg border dark:border-gray-700">
        <div className="flex justify-between items-center mb-2">
          <Heading size="md">Find</Heading>
          <Icon
            icon={faMagnifyingGlass}
            size="xl"
            className="fill-gray-900 dark:fill-gray-400"
          />
        </div>
        <Text size="md">
          Search and browse for new books – or find inspiration in other
          reader&apos;s libraries.
        </Text>
      </div>

      <div className="dark:bg-gray-900 p-6 rounded-lg border dark:border-gray-700">
        <div className="flex justify-between items-center mb-2">
          <Heading size="md">Track</Heading>
          <Icon
            icon={faBookmark}
            size="xl"
            className="fill-gray-900 dark:fill-gray-400"
          />
        </div>
        <Text size="md">
          Track every book by want to read, currently reading, read and did not
          finish.
        </Text>
      </div>

      <div className="dark:bg-gray-900 p-6 rounded-lg border dark:border-gray-700">
        <div className="flex justify-between items-center mb-2">
          <Heading size="md">Connect</Heading>
          <Icon
            icon={faCommentSmile}
            size="xl"
            className="fill-gray-900 dark:fill-gray-400"
          />
        </div>
        <Text size="md">
          Explore others reader's bookshelves and follow for their next reads.
        </Text>
      </div>

      <div className="dark:bg-gray-900 p-6 rounded-lg border dark:border-gray-700">
        <div className="flex justify-between items-center mb-2">
          <Heading size="md">Discover</Heading>
          <Icon
            icon={faTelescope}
            size="xl"
            className="fill-gray-900 dark:fill-gray-400"
          />
        </div>
        <Text size="md">
          Use our set of amazing stats and tools, including AI, to discover new
          horizons in your reading journey.
        </Text>
      </div>
    </Container>
  );
}
