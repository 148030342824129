import { ComponentTheme } from "hardcover-ui/types/base";

export type LoadingSpinnerThemeProps = {
  variant: "primary" | "layout" | "button" | "none";
  size: "sm" | "md" | "lg";
};

const sharedThemeStyles =
  "animate-reversespin dark:text-gray-400 text-gray-700";
export const loadingSpinnerTheme: ComponentTheme<LoadingSpinnerThemeProps> = {
  variant: {
    primary: sharedThemeStyles,
    layout: `${sharedThemeStyles} my-8 mx-auto w-8 h-8`,
    button: `${sharedThemeStyles} text-gray-100`,
    none: "animate-reversespin",
  },
  size: {
    sm: "w-4 h-4",
    md: "w-6 h-6",
    lg: "w-8 h-8",
  },
};
