"use client";

import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { UserType } from "types";

const getState = (state: RootState) => state;

export const getCurrentUserChannel = createSelector(
  getState,
  (state) => state.currentUser
);

export const getCurrentUserGdprSelector = createSelector(
  getCurrentUserChannel,
  ({ gdpr }) => {
    return gdpr;
  }
);

export const getSession = createSelector(
  getCurrentUserChannel,
  ({ session }) => {
    return session;
  }
);

export const getReloadUser = createSelector(
  getCurrentUserChannel,
  ({ reloadUser }) => {
    return reloadUser;
  }
);

export const getTokenSelector = createSelector(
  getCurrentUserChannel,
  ({ session }) => {
    return session?.token;
  }
);

export const getUserId = createSelector(
  getCurrentUserChannel,
  ({ session }) => {
    return session?.id;
  }
);

export const getIsLoggedIn = createSelector(
  getCurrentUserChannel,
  ({ session }) => {
    if (!session?.token) {
      return null;
    }
    return session?.id > 0;
  }
);

export const getIsSessionLoading = createSelector(
  getCurrentUserChannel,
  ({ session }) => {
    return session?.token === null;
  }
);

export const getIsUserLoading = createSelector(
  getCurrentUserChannel,
  ({ session, userLoaded }) => {
    return session?.id && !userLoaded;
  }
);

function isUserLoaded(session, userLoaded) {
  // Logged in, loaded
  if (userLoaded) {
    return true;
  }

  // Not logged in, true
  if (session?.token && !session?.id && !userLoaded) {
    return true;
  }

  // Not loaded
  return false;
}

export const getIsUserLoaded = createSelector(
  getCurrentUserChannel,
  ({ session, userLoaded }) => {
    return isUserLoaded(session, userLoaded);
  }
);

export const getUserLoaded = getIsUserLoaded;

export const userLoadingState = createSelector(
  getCurrentUserChannel,
  ({ session, userLoaded }) => {
    return {
      userLoaded: isUserLoaded(session, userLoaded),
      currentUserId: session?.id,
      isLoggedIn: session?.id > 0,
    };
  }
);

export type CurrentUserFilter = (user: UserType) => any;
export const getUserWithFilter: (state: any, filter: CurrentUserFilter) => any =
  createSelector(
    getCurrentUserChannel,
    (_, filter: CurrentUserFilter) => filter,
    ({ user }, filter: CurrentUserFilter) => {
      return filter(user || {});
    }
  );

export const getUnfoundBookMatchScores = createSelector(
  getCurrentUserChannel,
  ({ unfoundBookMatchScores }) => {
    return unfoundBookMatchScores;
  }
);
