import Button from "hardcover-ui/components/Button";
import faApple from "icons/brands/apple.svg";
import faAndroid from "icons/brands/android.svg";
import Icon from "hardcover-ui/components/Icon";

const AppStoreBadges = ({ className }) => {
  return (
    <>
      <div
        className={`flex gap-2 flex-wrap sm:justify-start justify-center ${className}`}
      >
        <Button
          as={{
            element: "link",
            props: {
              href: "https://apps.apple.com/us/app/hardcover-app/id1663379893",
            },
          }}
          size="sm"
          variant="tertiary"
        >
          <Icon
            icon={faApple}
            size="md"
            className="fill-gray-900 dark:fill-gray-200"
          />
          Get iOS App
        </Button>
        <Button
          as={{
            element: "link",
            props: {
              href: "https://play.google.com/store/apps/details?id=hardcover.app",
            },
          }}
          size="sm"
          variant="tertiary"
        >
          <Icon
            icon={faAndroid}
            size="md"
            className="fill-gray-900 dark:fill-gray-200"
          />
          Get Android App
        </Button>
      </div>
    </>
  );
};

export default AppStoreBadges;
