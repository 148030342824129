/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { UserBookType } from "types";
import ListBookType from "types/ListBookType";
import TaggingType from "types/TaggingType";

export type BookButtonStateType = {
  open: boolean;
  bookId: number;
  referrerUserId: number;
  isLoaded: boolean;
  userBook: UserBookType | null;
  taggings: TaggingType[];
  listBooks: ListBookType[];
};

export const bookButtonState: BookButtonStateType = {
  open: false,
  bookId: null,
  referrerUserId: null,
  isLoaded: false,
  userBook: null,
  taggings: [],
  listBooks: [],
};

const bookButtonSlice = createSlice({
  name: "BookButtonSlice",
  initialState: bookButtonState,
  reducers: {
    showBookDrawer(
      state,
      {
        payload: { bookId, referrerUserId },
      }: {
        payload: {
          bookId: number;
          referrerUserId?: number;
        };
      }
    ) {
      state.open = true;
      state.bookId = bookId;
      state.referrerUserId = referrerUserId;
    },
    isLoaded(state) {
      state.isLoaded = true;
    },
    hideBookDrawer(state) {
      state.open = false;
      state.bookId = null;
      state.referrerUserId = null;
      state.taggings = [];
      state.listBooks = [];
    },
    reset(state) {
      state.bookId = null;
      state.referrerUserId = null;
      state.taggings = [];
      state.listBooks = [];
    },
    setUserBook(
      state,
      { payload: { userBook } }: { payload: { userBook: UserBookType } }
    ) {
      state.userBook = userBook;
    },
    setTaggings(
      state,
      { payload: { taggings } }: { payload: { taggings: TaggingType[] } }
    ) {
      state.taggings = taggings;
    },
    setListBooks(
      state,
      { payload: { listBooks } }: { payload: { listBooks: ListBookType[] } }
    ) {
      state.listBooks = listBooks;
    },
  },
});

export const bookButtonActions = bookButtonSlice.actions;

export default bookButtonSlice.reducer;
