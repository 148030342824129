/* eslint-disable react/jsx-props-no-spreading */
import classNames from "lib/classNames";
import { iconTheme, IconThemeProps } from "hardcover-ui/theme/components/icon";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

type Props = {
  icon: any;
  variant?: IconThemeProps["variant"];
  size?: IconThemeProps["size"];
} & Partial<HTMLAttributes<SVGElement>>;

const Icon = forwardRef<SVGElement, PropsWithChildren<Props>>(
  ({ icon, size = "md", variant = "normal", className = "", ...rest }, ref) => {
    const finalClassName = classNames(
      iconTheme.size?.[size],
      iconTheme.variant?.[variant],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      ...rest,
    };
    const SvgIcon = icon;

    return <SvgIcon {...props} />;
  }
);

export default Icon;
