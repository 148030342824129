/* eslint-disable react/jsx-props-no-spreading */
import classNames from "lib/classNames";
import RefreshIcon from "@heroicons/react/outline/RefreshIcon";
import {
  loadingSpinnerTheme,
  LoadingSpinnerThemeProps,
} from "hardcover-ui/theme/components/loading-spinner";

type Props = {
  size?: LoadingSpinnerThemeProps["size"];
  variant?: LoadingSpinnerThemeProps["variant"];
  className?: string;
};

export default function LoadingSpinner({
  size = "md",
  variant = "primary",
  className,
  ...rest
}: Props) {
  const finalClassName = classNames(
    loadingSpinnerTheme.variant[variant],
    loadingSpinnerTheme.size?.[size],
    className
  );

  const props = {
    className: finalClassName,
    ...rest,
  };

  return <RefreshIcon {...props} />;
}
