"use client";

import { getUserId } from "store/currentUser/currentUserSelector";
import { useSelector } from "react-redux";
import { Suspense, lazy } from "react";
import useMount from "hooks/useMount";

const UserMatchPercentageForUser = lazy(
  () => import("./UserMatchPercentageForUser")
);

export interface Props {
  userId: number;
  size?: "sm" | "md" | "lg";
}

export default function UserMatchPercentage({ userId, size = "sm" }: Props) {
  const currentUserId = useSelector(getUserId);
  const mount = useMount();

  if (!mount || !currentUserId) {
    return <></>;
  }
  return (
    <Suspense>
      <UserMatchPercentageForUser
        userId={userId}
        currentUserId={currentUserId}
        size={size}
      />
    </Suspense>
  );
}
