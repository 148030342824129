/* eslint-disable react/jsx-props-no-spreading */
import {
  containerTheme,
  ContainerThemeProps,
} from "hardcover-ui/theme/components/container";
import classNames from "lib/classNames";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

type Props = {
  variant?: ContainerThemeProps["variant"];
  size?: ContainerThemeProps["size"];
  className?: string;
  id?: string;
} & Partial<HTMLAttributes<HTMLDivElement>>;

const Container = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    { children, variant = "layout", size = "sm", id, className, ...rest },
    ref
  ) => {
    const finalClassName = classNames(
      containerTheme.variant[variant],
      containerTheme.size?.[size],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      id,
      ...rest,
    };

    return <div {...props}>{children}</div>;
  }
);

export default Container;
