"use client";

import Button from "hardcover-ui/components/Button";
import Container from "hardcover-ui/components/Container";
import Heading from "hardcover-ui/components/Heading";
import Text from "hardcover-ui/components/Text";

import Badge from "hardcover-ui/components/Badge";
import UserJoinButton from "components/join/UserJoinButton";

import { Tab } from "@headlessui/react";
import { Fragment } from "react";
import { cropUrl } from "lib/imageTools";

// const url1Width = 388;
// const url1Height = 292;
const url1Width = 485;
const url1Height = 365;

const url2Width = 776;
const url2Height = 584;

const url1x = cropUrl({
  path: "/static/pages/home/julesRocket.webp",
  width: url1Width,
  height: url1Height,
});
const url2x = cropUrl({
  path: "/static/pages/home/julesRocket.webp",
  width: url2Width,
  height: url2Height,
});

export default function SupporterPlan({ loggedIn }: { loggedIn: boolean }) {
  return (
    <Container size="md" className="overflow-hidden sm:overflow-visible">
      <div className="grid sm:grid-cols-5 items-center relative border-2 dark:border-gray-700 rounded-xl mt-10 dark:bg-gray-950 bg-slate-100">
        <img
          src={url1x}
          srcSet={`${url1x} ${url1Width}w, ${url2x} ${url2Width}w`}
          alt="Jules on a rocket"
          width={url1Width}
          height={url1Height}
          loading="lazy"
          className="lg:-ml-12 -mt-12 -mb-12 sm:mb-0 col-span-2"
        />
        <div className="h-full p-6 sm:col-span-3 flex flex-col justify-center gap-2">
          <Text variant="secondary">Make a difference</Text>
          <Heading size="lg">Become a Supporter</Heading>
          <Text>
            Hardcover's free forever, but we thrive on your bookish love. Join
            our Supporter plan and be our literary hero!
          </Text>
          <Tab.Group>
            <Tab.List>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    type="button"
                    className={
                      selected
                        ? "bg-indigo-600 text-indigo-50 px-2 py-1 rounded-l-xl"
                        : "bg-gray-300 dark:bg-gray-800 px-2 py-1 rounded-l-xl"
                    }
                  >
                    Monthly
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    type="button"
                    className={
                      selected
                        ? "bg-indigo-600 text-indigo-50 px-2 py-1 rounded-r-xl"
                        : "bg-gray-300 dark:bg-gray-800 px-2 py-1 rounded-r-xl"
                    }
                  >
                    Yearly
                    <span
                      className={
                        selected
                          ? "text-xs text-indigo-300"
                          : "text-xs text-gray-400"
                      }
                    >
                      {" "}
                      Save 20%
                    </span>
                  </button>
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <p className="text-4xl font-serif my-4">
                  $ 4.99
                  <span className="ml-1 text-base text-gray-400">/month</span>
                </p>
              </Tab.Panel>
              <Tab.Panel>
                <div className="flex flex-wrap gap-1 items-center my-4">
                  <p className="text-4xl font-serif ">
                    $ 49.99
                    <span className="ml-1 text-base text-gray-400">/year</span>
                  </p>
                  <span className="w-fit text-sm px-2 py-1 bg-indigo-800 text-indigo-300 rounded-full">
                    Save 20%
                  </span>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <div className="absolute right-6 bottom-6">
            {loggedIn ? (
              false
            ) : (
              <UserJoinButton variant="tertiary" size="lg" tab="Join">
                <>Sign Up</>
              </UserJoinButton>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        <Text>What you get:</Text>
        <Badge variant="secondary">Powered up book stats</Badge>{" "}
        <Badge variant="secondary">Supporter Flair</Badge>{" "}
        <Badge variant="secondary">Edit books</Badge>
        <Button size="xs" variant="secondary">
          More
        </Button>
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        <Text variant="secondary">Did you know?</Text>
        <Text>
          We donate 10% of our total earnings to causes voted on by our
          Supporters.
        </Text>
      </div>
    </Container>
  );
}
