/* eslint-disable react/jsx-props-no-spreading */
import classNames from "lib/classNames";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";
import { textTheme, TextThemeProps } from "hardcover-ui/theme/components/text";

type Props = {
  as?: TextThemeProps["as"];
  size?: TextThemeProps["size"];
  variant?: TextThemeProps["variant"];
} & Partial<HTMLAttributes<HTMLParagraphElement>>;

const Text = forwardRef<HTMLParagraphElement, PropsWithChildren<Props>>(
  (
    {
      size = "md",
      as = "span",
      variant = "normal",
      children,
      className,
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      textTheme.variant[variant],
      textTheme.size?.[size],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      ...rest,
    };

    if (as === "p") {
      return <p {...props}>{children}</p>;
    }
    if (as === "div") {
      return <div {...props}>{children}</div>;
    }
    if (as === "h2") {
      return <h2 {...props}>{children}</h2>;
    }

    return <span {...props}>{children}</span>;
  }
);

export default Text;
