import { ComponentTheme } from "hardcover-ui/types/base";

export type CoverProps = {
  variant: "none" | "accent" | "primary" | "secondary";
  size: "xxs" | "xs" | "sm" | "md" | "lg";
  settings: {
    showStatus?: boolean;
    stretch?: "x" | "y";
    tooltip?: boolean;
    details?: boolean;
    eager?: boolean;
  };
};

const coverBaseStyles = "relative overflow-hidden group transition-all";
const borderStyles = "rounded-l-sm rounded-r-md border";

export const coverTheme: ComponentTheme<CoverProps> = {
  variant: {
    none: coverBaseStyles,
    primary: `${coverBaseStyles} ${borderStyles} border border-primary`,
    accent: `${coverBaseStyles} ${borderStyles} border border-accent`,
    secondary: `${coverBaseStyles} ${borderStyles} border border-secondary`,
  },
  size: {
    xxs: "",
    xs: "",
    sm: "",
    md: "",
    lg: "",
  },
};

export type CoverLinkProps = {
  variant: "none" | "accent" | "primary" | "secondary";
  size: "xs" | "sm" | "md" | "lg";
};

export const coverThemeLink: ComponentTheme<CoverLinkProps> = {
  variant: {
    none: `${coverBaseStyles} border border-gray-100/20 ring-accent hover:ring-1 hover:border-accent`,
    primary: `${coverBaseStyles} border border-primary ring-accent hover:ring-1 hover:border-accent`,
    accent: `${coverBaseStyles} border border-accent ring-primary hover:ring-1 hover:border-primary`,
    secondary: `${coverBaseStyles}  border border-secondary ring-accent hover:ring-1 hover:border-accent`,
  },
  size: {
    xs: "rounded-l-sm rounded-r-md shadow-sm",
    sm: "rounded-l-sm rounded-r-md shadow-sm",
    md: "rounded-l-sm rounded-r-md shadow-md",
    lg: "rounded-l-sm rounded-r-md shadow-lg",
  },
};
