export const DEFAULT_OPENGRAPH_IMAGE = `${process.env.NEXT_PUBLIC_CDN_URL}/static/ogimages/opengraph_card_large.png`;
export const HARDCOVER_LOGO_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/logos/hardcover-logo-with-text.png`;

// Avatar URLS
export const AVATAR_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile1.png`;
export const AVATAR_URL_2 = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile2.png`;
export const AVATAR_URL_3 = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile3.png`;
export const AVATAR_URL_4 = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile4.png`;
export const AVATAR_URL_5 = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile5.png`;
export const AVATAR_URL_6 = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/profile6.png`;
export const AUTHOR_AVATAR_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/author.svg`;
export const ADAM_AVATAR_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/adam.webp`;
export const STE_AVATAR_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/avatars/ste.jpeg`;

export const DEFAULT_COVER_URL = `${process.env.NEXT_PUBLIC_CDN_URL}/static/covers/cover1.png`;

export function normalizeImageUrl(url: string) {
  if (!url) return url;
  const normalizeUrl = url
    .replace(
      "https://hardcover-staging.imgix.net",
      "https://storage.googleapis.com/hardcover-staging"
    )
    .replace(
      "https://hardcover.imgix.net",
      "https://storage.googleapis.com/hardcover"
    )
    .replace(
      "https://storage.googleapis.com/hardcover-staging",
      "https://assets-staging.hardcover.app"
    )
    .replace(
      "https://storage.googleapis.com/hardcover",
      "https://assets.hardcover.app"
    );
  return normalizeUrl;
}

export function cdnUrl(path: string) {
  return `${process.env.NEXT_PUBLIC_CDN_URL}${path}`;
}

export function enlargeUrl({
  path,
  url,
  width,
  height,
}: {
  url?: string;
  path?: string;
  width: number;
  height: number;
}) {
  if (path) {
    return `${process.env.NEXT_PUBLIC_RESIZE_URL}/enlarge?url=${process.env.NEXT_PUBLIC_CDN_URL}${path}&width=${width}&height=${height}&type=webp`;
  } else if (url) {
    return `${process.env.NEXT_PUBLIC_RESIZE_URL}/enlarge?url=${url}&width=${width}&height=${height}&type=webp`;
  }
}

export function cropUrl({
  path,
  url,
  width,
  height,
}: {
  url?: string;
  path?: string;
  width: number;
  height: number;
}) {
  if (path) {
    return `${process.env.NEXT_PUBLIC_RESIZE_URL}/crop?url=${process.env.NEXT_PUBLIC_CDN_URL}${path}&width=${width}&height=${height}&type=webp`;
  } else if (url) {
    return `${process.env.NEXT_PUBLIC_RESIZE_URL}/crop?url=${url}&width=${width}&height=${height}&type=webp`;
  }
}

export function imgixImageUrl(url: string) {
  return url
    .replace(process.env.NEXT_PUBLIC_ASSET_URL, "https://hardcover.imgix.com")
    .replace(process.env.NEXT_PUBLIC_CDN_URL, "https://hardcover.imgix.com");
}
