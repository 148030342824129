/* eslint-disable react/jsx-props-no-spreading */
import classNames from "lib/classNames";
import {
  badgeTheme,
  BadgeThemeProps,
} from "hardcover-ui/theme/components/badge";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

type Props = {
  size?: BadgeThemeProps["size"];
  variant?: BadgeThemeProps["variant"];
  className?: string;
} & Partial<HTMLAttributes<HTMLSpanElement>>;

const Badge = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ size = "md", variant = "active", children, className, ...rest }, ref) => {
    const finalClassName = classNames(
      badgeTheme.variant[variant],
      badgeTheme.size?.[size],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      ...rest,
    };

    return <span {...props}>{children}</span>;
  }
);

export default Badge;
