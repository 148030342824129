"use client";

import { ReactElement, Suspense, lazy, useMemo, useState } from "react";
import { ButtonThemeProps } from "hardcover-ui/theme/components/button";
import UserJoinButtonInactive from "./UserJoinButtonInactive";

const UserJoinButtonActive = lazy(() => import("./UserJoinButtonActive"));

export default function UserJoinButton({
  children,
  variant = "tertiary",
  size = "md",
  tab = "Login",
}: {
  children: ReactElement;
  variant?: ButtonThemeProps["variant"];
  size?: ButtonThemeProps["size"];
  tab: "Join" | "Login";
}) {
  const [clicked, setClicked] = useState(false);

  const InactiveButton = useMemo(() => {
    return (
      <UserJoinButtonInactive
        variant={variant}
        size={size}
        onClick={() => setClicked(true)}
      >
        {children}
      </UserJoinButtonInactive>
    );
  }, []);

  // Don't load Headless UI and the entire dropdown component unless it's clicked on
  if (clicked) {
    return (
      <Suspense fallback={InactiveButton}>
        <UserJoinButtonActive variant={variant} size={size} tab={tab}>
          {children}
        </UserJoinButtonActive>
      </Suspense>
    );
  }

  return <>{InactiveButton}</>;
}
