/* eslint-disable react/jsx-props-no-spreading */
import {
  headingTheme,
  HeadingThemeProps,
} from "hardcover-ui/theme/components/heading";
import classNames from "lib/classNames";
import { forwardRef, HTMLAttributes, PropsWithChildren } from "react";

type Props = {
  variant?: HeadingThemeProps["variant"];
  size?: HeadingThemeProps["size"];
  level?: HeadingThemeProps["level"];
  className?: string;
} & Partial<HTMLAttributes<HTMLHeadingElement>>;

const Heading = forwardRef<HTMLHeadingElement, PropsWithChildren<Props>>(
  (
    {
      children,
      className,
      size = "lg",
      level = 1,
      variant = "primary",
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      headingTheme.variant[variant],
      headingTheme.size?.[size],
      className
    );

    const props = {
      ref,
      className: finalClassName,
      ...rest,
    };

    switch (level) {
      case 1:
        return <h1 {...props}>{children}</h1>;
      case 2:
        return <h2 {...props}>{children}</h2>;
      case 3:
        return <h3 {...props}>{children}</h3>;
      case 4:
        return <h4 {...props}>{children}</h4>;
      case 5:
        return <h5 {...props}>{children}</h5>;
      case 6:
        return <h6 {...props}>{children}</h6>;
      default:
        return <h1 {...props}>{children}</h1>;
    }
  }
);

export default Heading;
