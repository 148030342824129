import { ComponentTheme } from "hardcover-ui/types/base";
import { textLinkTheme } from "hardcover-ui/theme/components/text";

export type ButtonThemeProps = {
  variant:
    | "primary"
    | "secondary"
    | "tertiary"
    | "accent"
    | "warning"
    | "ghost"
    | "unstyled"
    | "link"
    | "dangerLink"
    | "outline"
    | "pill"
    | "none"
    | "base";
  size: "none" | "xs" | "sm" | "md" | "lg";
};

const buttonBaseStyles =
  "inline-flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border rounded-lg active:translate-y-1 transition-all w-fit";

export const buttonTheme: ComponentTheme<ButtonThemeProps> = {
  variant: {
    none: `hover:bg-gray-300 focus:ring-gray-200 dark:hover:bg-gray-900 dark:focus:ring-gray-600`,
    base: buttonBaseStyles,
    primary: `${buttonBaseStyles} bg-indigo-600 border-indigo-600 hover:bg-indigo-700 text-white`,
    secondary: `${buttonBaseStyles} bg-white border-gray-300 hover:bg-gray-50 dark:bg-gray-300 dark:hover:bg-gray-100 text-gray-700`,
    tertiary: `${buttonBaseStyles} bg-white hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-800 text-gray-900 dark:text-white dark:border-gray-700 dark:hover:border-gray-700`,
    accent: `${buttonBaseStyles} border-yellow-400 bg-yellow-400 text-gray-800 hover:bg-yellow-300 focus:ring-yellow-600`,
    warning: `${buttonBaseStyles} bg-red-500 border-red-500 hover:bg-red-600 text-white`,
    ghost: `rounded-lg active:translate-y-1 transition-all w-fit inline-flex items-center dark:bg-gray-900/30 dark:hover:bg-gray-900 bg-gray-100/30 hover:bg-gray-100`,
    unstyled: "inline-flex items-center",
    outline: `${buttonBaseStyles} border border-gray-200 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-yellow-400`,
    link: textLinkTheme.variant.normal,
    pill: "inline-flex flex-row items-center bg-gray-100 hover:bg-indigo-100 dark:ring-indigo-600 text-gray-600 dark:bg-gray-900 dark:text-gray-400 rounded-full hover:ring-2",
    dangerLink:
      "text-red-600 dark:text-red-400 underline underline-offset-2 dark:hover:text-red-500 hover:text-red-400",
  },
  size: {
    none: "",
    xs: "py-0.2 px-1.5 text-sm font-normal",
    sm: "py-1 px-2 text-sm font-medium",
    md: "py-2 px-4 text-sm font-medium",
    lg: "py-3 px-4 text-lg font-medium shadow-button active:shadow-none",
  },
};
