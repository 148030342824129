/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import LoadingSpinner from "hardcover-ui/components/LoadingSpinner";
import {
  ButtonThemeProps,
  buttonTheme,
} from "hardcover-ui/theme/components/button";
import classNames from "lib/classNames";
import Link, { LinkProps } from "next/link";
import { ButtonHTMLAttributes, PropsWithChildren, forwardRef } from "react";

type Props = {
  as?: {
    element: "link";
    props: LinkProps;
  };
  variant?: ButtonThemeProps["variant"];
  size?: ButtonThemeProps["size"];
  className?: string;
  isLoading?: boolean;
  gap?: number;
} & Partial<ButtonHTMLAttributes<HTMLButtonElement>>;

const Button = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  (
    {
      as,
      children,
      size,
      variant = "unstyled",
      className,
      isLoading,
      gap = 2,
      onMouseUp,
      onMouseDown,
      onClick,
      ...rest
    },
    ref
  ) => {
    const finalClassName = classNames(
      buttonTheme.variant[variant],
      buttonTheme.size?.[size],
      `gap-${gap}`,
      className
    );

    const props = {
      ref,
      className: finalClassName,
      onMouseUp: isLoading ? () => null : onMouseUp,
      onMouseDown: isLoading ? () => null : onMouseDown,
      onClick: isLoading ? () => null : onClick,
      ...rest,
    };

    const content = (
      <>
        {children}
        {isLoading && <LoadingSpinner size="sm" />}
      </>
    );

    if (as?.element === "link") {
      return (
        <Link className={finalClassName} {...as.props}>
          {content}
        </Link>
      );
    }

    return (
      <button type="button" {...props}>
        {content}
      </button>
    );
  }
);

export default Button;
