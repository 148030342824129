"use client";

/* eslint-disable react/jsx-props-no-spreading */
import { useDispatch, useSelector } from "react-redux";
import { useLongPress } from "use-long-press";
import { bookButtonActions } from "store/bookButton/bookButtonSlice";
import { getIsLoggedIn } from "store/currentUser/currentUserSelector";
import { ReactElement } from "react";

export default function HandleCoverLongPress({
  bookId,
  children,
}: {
  bookId: number;
  children: ReactElement;
}) {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const dispatch = useDispatch();
  const longPress = useLongPress(() => {
    if (isLoggedIn) {
      dispatch(bookButtonActions.showBookDrawer({ bookId }));
    }
  });

  return <div {...longPress()}>{children}</div>;
}
