"use client";

import { getUserId } from "store/currentUser/currentUserSelector";
import useMount from "hooks/useMount";
import { Suspense, lazy } from "react";
import { useSelector } from "react-redux";

const PromptUserAnswersCount = lazy(() => import("./PromptUserAnswersCount"));

export default function PromptUserAnswersCountLoader({
  promptId,
}: {
  promptId: number;
}) {
  const userId = useSelector(getUserId);
  const mounted = useMount();

  if (!userId || !mounted) {
    return <></>;
  }

  return (
    <Suspense>
      <PromptUserAnswersCount userId={userId} promptId={promptId} />
    </Suspense>
  );
}
