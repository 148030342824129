import { ComponentTheme } from "hardcover-ui/types/base";

export type TextThemeProps = {
  variant:
    | "title"
    | "normal"
    | "secondary"
    | "inherit"
    | "warning"
    | "badge"
    | "accent";
  size: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  as: "p" | "span" | "div" | "h2";
};

export const textTheme: ComponentTheme<TextThemeProps> = {
  variant: {
    title: "font-serif text-gray-900 dark:text-yellow-50 font-semibold",
    normal: `text-gray-800 dark:text-gray-200`,
    secondary: `text-gray-600 dark:text-gray-400`,
    warning: "text-red-600 dark:text-red-500",
    accent: "text-indigo-600 dark:text-yellow-400",
    inherit: "",
    badge:
      "uppercase text-xs bg-indigo-600 text-white py-0.5 px-1 ml-2 rounded",
  },
  size: {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-md",
    lg: "text-lg",
    xl: "text-xl",
    "2xl": "text-2xl",
  },
};

export type TextLinkThemeProps = {
  variant:
    | "none"
    | "normal"
    | "secondary"
    | "accent"
    | "onAccent"
    | "breadcrumb"
    | "ghost"
    | "invert"
    | "warning"
    | "title";
  size: "xs" | "sm" | "md" | "lg" | "xl";
};

const textLinkBaseStyles = "transition-all underline-offset-2";

export const textLinkTheme: ComponentTheme<TextLinkThemeProps> = {
  variant: {
    none: "",
    title: "font-serif text-gray-900 dark:text-yellow-50 underline-offset-4",
    invert: "no-underline hover:underline",
    normal: `${textLinkBaseStyles} text-gray-800 dark:text-gray-100 `,
    secondary: `${textLinkBaseStyles} text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300`,
    ghost: `text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300`,
    accent: `${textLinkBaseStyles} text-indigo-600 dark:text-yellow-400`,
    onAccent: `${textLinkBaseStyles} text-gray-800 dark:text-gray-800 `,
    breadcrumb: `text-gray-700 dark:text-gray-400 text-lg space-x-2 font-semibold inline-flex items-center`,
    warning: "text-red-600 dark:text-red-500",
  },
  size: {
    xs: "text-xs",
    sm: "text-sm",
    md: "text-md",
    lg: "text-lg",
    xl: "text-xl",
  },
};
