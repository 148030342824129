import { ComponentTheme } from "hardcover-ui/types/base";

export type BadgeThemeProps = {
  variant: "accent" | "primary" | "secondary" | "success" | "warning" | "ghost";
  size: "sm" | "md" | "lg";
};

const badgeBaseStyles = "font-semibold whitespace-nowrap";

export const badgeTheme: ComponentTheme<BadgeThemeProps> = {
  variant: {
    primary: `${badgeBaseStyles} bg-indigo-600 text-white`,
    accent: `${badgeBaseStyles} bg-yellow-400 text-yellow-900`,
    secondary: `${badgeBaseStyles} bg-gray-200 dark:bg-gray-600 text-gray-900 dark:text-gray-300`,
    success: `${badgeBaseStyles} bg-green-100 text-green-600 dark:bg-green-600 dark:text-white`,
    warning: `${badgeBaseStyles} bg-red-500 text-red-50`,
    ghost: `${badgeBaseStyles} bg-gray-400 dark:bg-gray-700 text-gray-600 dark:text-gray-500`,
  },
  size: {
    sm: "rounded-lg text-xs px-1 py-0.5",
    md: "rounded-lg text-sm px-1 py-0.5",
    lg: "rounded-lg text-base px-1.5 py-1",
  },
};
