"use client";

/* eslint-disable @typescript-eslint/no-empty-function */
import Button from "hardcover-ui/components/Button";
import { ButtonThemeProps } from "hardcover-ui/theme/components/button";
import { ReactElement } from "react";

export default function UserJoinButtonInactive({
  children,
  variant = "tertiary",
  size = "md",
  onClick = () => {},
}: {
  children: ReactElement;
  variant?: ButtonThemeProps["variant"];
  size?: ButtonThemeProps["size"];
  onClick?: () => void;
}) {
  return (
    <Button variant={variant} size={size} onClick={onClick}>
      {children}
    </Button>
  );
}
